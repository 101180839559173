import { breakpoints } from '@utils';

type SizesString = {
  vwMobile?: string;
  vwTablet?: string;
  vwLaptop?: string;
};

export const generateResponsiveSizesString = ({
  vwMobile,
  vwTablet,
  vwLaptop
}: SizesString) => {
  let responsiveSizeCtrl = '';

  if (vwMobile)
    responsiveSizeCtrl += `(max-width:${breakpoints.tablet}) ${vwMobile},`;

  if (vwTablet)
    responsiveSizeCtrl += `(min-width:${breakpoints.tablet}) ${vwTablet},`;

  if (vwLaptop)
    responsiveSizeCtrl += `(min-width:${breakpoints.laptop}) ${vwLaptop}`;

  return responsiveSizeCtrl;
};
