import clsx from 'clsx';

const StoryCardFooter = ({
  children,
  hasBorder = false,
  orientation = 'row'
}: {
  children: React.ReactNode;
  hasBorder: boolean;
  orientation?: 'column' | 'row';
}) => (
  <div
    className={clsx('w-full', {
      'px-6': orientation === 'column',
      'px-6 tablet:px-0': orientation === 'row'
    })}
  >
    <div
      className={clsx('flex items-center justify-between flex-1 py-4', {
        'tablet:px-10': orientation === 'row',
        'border-warm-gray-2 border-t': hasBorder,
        'pt-0': !hasBorder
      })}
    >
      {children}
    </div>
  </div>
);

export default StoryCardFooter;
