import { Tag } from '@atoms';

const Tags = ({
  isAllClickable = false,
  onClick,
  size,
  tags,
  hasIcons = true,
  tagClassName
}: {
  isAllClickable?: boolean;
  onClick?: (tag: string) => void;
  size?: TTagSize;
  tags: ITag[];
  hasIcons?: boolean;
  tagClassName?: string;
}) => (
  <div className="flex flex-wrap gap-2" data-testid="tags-container">
    {tags.map(({ isClickable, value, url }) => (
      <Tag
        className={tagClassName}
        hasIcon={hasIcons}
        key={`tag-${value}`}
        value={value}
        onClick={() => onClick?.(value)}
        isClickable={isAllClickable || isClickable}
        size={size}
        url={url}
      />
    ))}
  </div>
);

export default Tags;
