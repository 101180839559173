import clsx from 'clsx';
import NextLink from 'next/link';

import { Icon } from '@atoms';
import { ArrowRightLine } from '@icons/System';
import { useLocale } from '@hooks';

const sizeStyle = {
  small: 'px-1.5 py-1 font-normal text-2xs focus:bg-black',
  default: 'px-2 py-1.5 ui-text'
};

const Tag = ({
  disabled = false,
  icon = ArrowRightLine,
  isClickable = false,
  onClick,
  size = 'default',
  isWrappable = true,
  style = 'primary',
  url,
  className,
  value,
  hasIcon,
  iconLeft = false
}: {
  disabled?: boolean;
  icon?: TIconType;
  isClickable?: boolean;
  hasIcon?: boolean;
  iconLeft?: boolean;
  onClick?: () => void;
  size?: TTagSize;
  isWrappable?: boolean;
  style?: 'primary' | 'secondary' | 'offer' | 'black';
  url?: string;
  className?: string;
  value: string;
}) => {
  const locale = useLocale();

  const tagClassName = clsx(
    'caption rounded-xl transition-all duration-500 border-2 border-transparent text-left',
    sizeStyle[size],
    {
      'inline-flex flex-row items-center gap-1': url || isClickable,
      'bg-hx-pearl text-black ': style === 'primary',
      'bg-white text-black': style === 'secondary',
      'bg-hx-pearl text-black font-normal': style === 'offer',
      'bg-black text-white': style === 'black',
      'whitespace-nowrap': !isWrappable,
      'bg-warm-gray-1': disabled,
      'hover:text-white focus:text-white active:bg-black active:text-white hover:bg-light-black focus:bg-light-black hover:border-black focus:border-black':
        isClickable,
      'hover:no-underline': url,
      [className || '']: className
    }
  );
  if (url) {
    return (
      <NextLink locale={locale} href={url} prefetch={false}>
        <a data-testid="tag-link" className={tagClassName}>
          {value}
          <Icon icon={icon} />
        </a>
      </NextLink>
    );
  }

  return isClickable ? (
    <button
      data-testid="tag-button"
      disabled={disabled}
      onClick={onClick}
      className={tagClassName}
    >
      {value}
      {hasIcon && <Icon icon={icon} />}
    </button>
  ) : (
    <div
      data-testid="tag-non-clickable"
      className={clsx(tagClassName, 'flex', { 'flex-row-reverse': iconLeft })}
    >
      <span>{value}</span>{' '}
      {hasIcon && <Icon size="xs" icon={icon} className="pr-1" />}{' '}
    </div>
  );
};

export default Tag;
